/* eslint-disable max-len */
import React from "react";

const MediumIcon: React.FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2881 2.06319H18V0H11.2678L9.03055 8.10521H8.96935L6.75242 0H0V2.06314H0.691533C0.851399 2.0887 0.999721 2.16098 1.11717 2.27056C1.23462 2.38013 1.31576 2.52192 1.35 2.67745V11.3695C1.31364 11.5195 1.23074 11.6546 1.11277 11.7562C0.994807 11.8579 0.847604 11.921 0.691533 11.9368H0V14H5.4V11.9368H4.05V2.80001H4.12877L7.24059 14H9.68139L12.8339 2.80001H12.9001V11.9368H11.55V14H18V11.9368H17.2881C17.1356 11.9173 16.9928 11.8525 16.8788 11.7512C16.7648 11.6498 16.6849 11.5166 16.65 11.3695V2.67751C16.6831 2.5248 16.7613 2.38506 16.8748 2.27579C16.9883 2.16652 17.1321 2.09257 17.2881 2.06319Z"
      fill="black"
    />
  </svg>
);

export default MediumIcon;
