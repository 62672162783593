import React, { ReactNode } from "react";
import classes from "./style.module.scss";

const Container: React.FC<{ children: ReactNode; classname?: string }> = ({
  children,
  classname,
}) => (
  <div className={`${classes.Container} ${classname || undefined}`}>
    {children}
  </div>
);

Container.defaultProps = {
  classname: undefined,
};

export default Container;
